<template>
  <v-container>
    <v-card>
      <v-system-bar height="47" color="primary">
        <h3 class="white--text">{{ title }}</h3>
        <v-spacer></v-spacer>
        <v-text-field
          dense
          solo
          height="20px"
          placeholder="Type Search Keyword and hit Enter Key"
          class="mt-6 mr-2"
          color="white"
          prepend-inner-icon="mdi-magnify"
          @keyup.enter.native="getData"
          v-model="search"
        ></v-text-field>
        <v-btn title color="warning" @click="newRecord" elevation="0">
          <v-icon color="white">mdi-plus</v-icon>
          New
        </v-btn>
      </v-system-bar>
      <v-divider />
      <v-card-text>
        <el-table :data="tableData" style="width: 100%">
          <el-table-column prop="name" label="Island Name"></el-table-column>
          <el-table-column
            width="250"
            prop="name"
            label="Atoll Name"
          ></el-table-column>
          <el-table-column
            width="250"
            prop="country"
            label="Country"
          ></el-table-column>
          <el-table-column fixed="right" label="..." width="40">
            <template slot-scope="scope">
              <el-button
                type="text"
                size="small"
                @click="viewRecord(scope.row)"
              >
                View
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </v-card-text>
      <v-divider />
    </v-card>
    <form-view
      :show="showForm"
      :data="selectedRow"
      :perm="permissions"
      @updated="getData"
      @close="
        selectedRow = null;
        showForm = false;
      "
    />
    <v-overlay :value="loading" :opacity="0.7">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </v-container>
</template>
<script>
import Vue from "vue";
import { Table, TableColumn, Button } from "element-ui";
Vue.use(Table);
Vue.use(TableColumn);
Vue.use(Button);
import formView from "./form.vue";
export default {
  created() {
    this.getData();
  },
  components: {
    formView,
  },
  data() {
    return {
      tableData: [],
      title: "Islands",
      search: "",
      currentPage: 1,
      perPage: 10,
      total: 0,
      sort: "name|asc",
      permissions: [],
      loading: false,
      showForm: false,
      selectedRow: null,
    };
  },
  methods: {
    newRecord() {
      this.showForm = true;
    },
    viewRecord(row) {
      this.selectedRow = row;
      this.showForm = true;
    },
    getData() {
      let f = {
        filter: this.search,
        page: this.currentPage,
        per_page: this.perPage,
        sort: this.sort,
      };
      this.loading = true;
      this.api("geo/islands/get", f)
        .then((response) => {
          this.loading = false;
          this.tableData = response.data;
          this.total = response.total;
          this.permissions = response.permissions;
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  computed: {
    allowCreate() {
      return (
        this.permissions.filter((e) => e.permission == "Create").length > 0
      );
    },
    allowEdit() {
      return this.permissions.filter((e) => e.permission == "Edit").length > 0;
    },
    allowDisable() {
      return (
        this.permissions.filter((e) => e.permission == "Disable").length > 0
      );
    },
    allowReports() {
      return (
        this.permissions.filter((e) => e.permission == "View Reports").length >
        0
      );
    },
  },
  name: "Islands",
};
</script>
